import React, {useState, useEffect, useCallback } from 'react'
import { useLocation, useHistory } from 'react-router-dom';
import axios from "axios";


import PostCodeModalDom from '../Modal/PostcodeModal';
import TermsConditionsModalDom from '../Modal/Terms_ConditionsModal';

import ModalPostCode from '../PostcodModal/index';

import BackToMainModalDom from '../Modal/BackToMainModal';
import BackToMainModal from '../BackToMainModal';
import {
    Container,
    PaymentInfoContainer,
    PaymentTitleWrapper,
    PaymentInfoTitle,
    Filename,
    DividingLine1,
    PaymentInfoInputWrapper,
    NameInputwrapper,
    NameInputTitle,
    NameInput,
    PhoneInputwrapper,
    PhoneInputTitle,
    PhoneInput,
    AddressInputwrapper,
    AddressInputTitle,
    PostcodeInputWrapper,
    PostcodeInput,
    EmailInputwrapper,
    EmailInputTitle,
    EmailInput,
    PostcodeSearchBtn,
    BasicAddressInput,
    DetailAddressInput,
    ReceivemethodInputwrapper,
    ReceivemethodTitle,
    ReceivemethodBtnWrapper,
    DeliveryBtn,
    VisitedBtn,
    SelectOfficeWrapper,
    SelectOfficeTitle,
    SelectOfficeListandMap,
    SelectOfficeList,
    ReceivemethodMap,
    DividingLine2,
    PaymentTypeAndPriceWrapper,
    PaymentTypeWrapper,
    CreditCard,
    PaymentTypeTitle,
    PaymentTypeBtnWrapper,
    PaymentDescWrapper,
    QuestionMark,
    PaymentDesc,
    PriceWrapper,
    PrintPriceWrapper,
    PrintPriceTitle,
    PrintPrice,
    ShippingfeeWrapper,
    ShippingfeeTitle,
    Shippingfee,
    DetailDevidingLine,
    TotalPriceWrapper,
    TotalPriceTitle,
    TotalPrice,
    DividingLine3,
    PaymentConsentCheckWrapper,
    PaymentAllConsentWrpper,
    PaymentConsentCheckLable,
    PaymentConsentCheckDesc,
    IsExpanded,
    TermsConditonBtn,
    PaymentConsentCheck,
    DetailConsentCheckWrapper,
    DetailConsentCheckBox,
    FinalPaymentBtn,
    PaymentInfoMessageBox_Post,
    PaymentInfoMessageBox_Map,
    PaymentInfoMessage,
    ShippingWaitingTime
} from "./PaymentInfoElement"
import OfficeComponent from "../OfficesList/OfficeComponent"
import MyMap from '../NaverMap/MyMap';
import { NavermapsProvider } from 'react-naver-maps';
import LoadingGIF from '../LoadingAnimation/index'
// import FinalPaymentBtn from "../../pages/payment"

import QuestionMark_Support from '../../Asset/Icons/QuestionMark_Support.png'
import PaymentPopUpUrl from '../../images/결제 설명 팝업창.png'
import TermsCondionsBtnUrl from '../../images/TermsConditionBtn.png'
import TermsConditionsModal from '../TermsConditions'


const PaymentInfo = (props) => {
    // <Filename>{props.value[0]}</Filename>

    const TookDakBaseUrl = process.env.REACT_APP_API_BASE_URL
    const TookDakMapID = process.env.REACT_APP_MAP_CLIENT_ID

    const history = useHistory();
    const location  = useLocation();
    const filename = location.state?.filename
    const price = location.state?.Price

    const [officesInfo, setOfficesInfo] = useState([])

    const [userNum, setUserNum] = useState("")

    const [userName, setUserName] = useState("")
    const [userPhoneNum, setUserPhoneNum] = useState("")
    const [userEmail, setUserEmail] = useState("")

    const [userNameErrMsg, setUserNameErrMsg] = useState("")
    const [userPhoneNumErrMsg, setUserPhoneNumErrMsg] = useState("")
    const [userEmailErrMsg, setUserEmailErrMsg] = useState("")
    const [detailAddressErrMsg, setDetailAddressErrMsg] = useState("")


    const [receivemethod, setReceivemethod] = useState("")

    const [postcode, setPostCode] = useState("");
    const [basicAddress, setBasicAddress] = useState("");
    const [detailAddress, setDetailAddress] = useState("")
    const [selectedOffice, setSelectedOffice] = useState(0);

    const [selectedPaymentType, SetSelectedPaymentType] = useState("")

    const [totalPrice, setTotalPrice] = useState(sessionStorage.getItem("NormalPrice"))

    const [normalPrice, setNormalPrice] = useState(sessionStorage.getItem("NormalPrice"))

    const [showPaymentPopup, setShowPaymentPopup] = useState(false);

    const [shippingFee, setShippingFee] = useState(0)

    const [LoadingGIFOpen, setLoadingGIFOpen] = useState(false)



    useEffect(() => {
        setLoadingGIFOpen(true)

        const fetchData = async () => {
            try {
              const response = await axios.get(TookDakBaseUrl + `/get_wait_time_predict_all_offices`);
              const taskId = response.data.task_id;
      
              const intervalId = setInterval(async () => {
                const response = await axios.get(TookDakBaseUrl + `/get-task-status?task_id=${taskId}`);
                console.log(response);
                if (response.data.task_status === 'SUCCESS') {
                    clearInterval(intervalId);
                    setOfficesInfo(response.data.offices)
                    setLoadingGIFOpen(false)
                }else if (response.data.task_status === 'FAILURE') {
                    clearInterval(intervalId);
                    console.log(response);
                    alert("지점 정보를 가져오지 못했습니다.")
                    setLoadingGIFOpen(false)
                  }
                }, 1000);
            } catch (error) {
                alert("지점 정보를 가져오지 못했습니다.")
            }
        };
        fetchData();
      }, [])
    
    
    const validateName = (userName) => {
        const regex = /^[가-힣A-Za-z]{1,10}$/;

        return regex.test(userName)
      };
    

    const onChangeName = useCallback((e) => {
        const currentName = e.target.value;

        setUserName(currentName)

        if(!validateName(currentName)){
            setUserNameErrMsg("1글자 이상 10자 이하의 영어와 한글로 입력해주세요.");
        }
    }, [])

    const validatePhoneNum = (phoneNum) => {
        const regex = /^\d{3}-?\d{3,4}-?\d{4}$/;
        return regex.test(phoneNum);
    };
    

    const onChangePhoneNum = useCallback((e) => {
        const inputValue = e.target.value;
        const currentPhoneNum = inputValue.replace(/[^0-9]/g, '');
        e.target.value = currentPhoneNum
        setUserPhoneNum(currentPhoneNum)

        if(!validatePhoneNum(currentPhoneNum)){
            setUserPhoneNumErrMsg("올바른 전화번호를 입력해주세요.");
        }
    }, [])

    
    const validateEmail = (email) => {
        return email
          .toLowerCase()
          .match(
            /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
          );
      };
      
    const onChangeEmail =  useCallback((e) => {
        const currentEmail = e.target.value;
        setUserEmail(currentEmail)

        if(!validateEmail(currentEmail)){
            setUserEmailErrMsg("이메일 형식이 올바르지 않습니다.");
        }else{
            setUserEmailErrMsg("");
        }
    }, [])

    const validateDetailAddress = (detaillAddress) => {
          // 주소가 너무 짧거나 너무 긴 경우를 검증
    if (detaillAddress.length < 3 || detaillAddress.length > 100) {
        return false;
    }

    // 주소가 특수 문자를 포함하고 있는지 검증
    const regex = /[!@#$%^&*(),.?":{}|<>]/g;
    if (regex.test(detaillAddress)) {
        return false;
    }

    return true;
    }
    const onChangeDetailAddress = useCallback((e) => {
        const currnetDetailAddress = e.target.value
        setDetailAddress(currnetDetailAddress)

        if(!validateDetailAddress(currnetDetailAddress)){
            setDetailAddressErrMsg("올바른 주소를 입력해주세요.")
        }
    }, [])

    const isNameVaild = validateName(userName)
    const isPhoneNumValid = validatePhoneNum(userPhoneNum)
    const isDetailAddressValid = validateDetailAddress(detailAddress)
    const isEmailValid = validateEmail(userEmail)
    // 팝업창 상태 관리
    const [isModalOpen, setIsModalOpen] = useState(false)

    // 팝업창 열기
    const openPostCode = () => {
        setIsModalOpen(true)
    }
    
    // 팝업창 닫기
    const closePostCode = () => {
        setIsModalOpen(false)
    }
    
    const RequestPrintAPI = async () =>{
        console.log(userNum);
        sessionStorage.setItem("Is_shipping",receivemethod === 'Delivery' ? true : false)
        sessionStorage.setItem("Shipping_address", postcode+"_"+basicAddress+"_"+detailAddress)
        sessionStorage.setItem("Shipping_with_mount",0)
        sessionStorage.setItem("Shipping_with_name","name")
        sessionStorage.setItem("Office_num", selectedOffice)
        sessionStorage.setItem("Price",totalPrice)
        const requestPrintData = {
            User_num: Number(sessionStorage.getItem('User_num')),
            File_name: sessionStorage.getItem('File_name'),
            Office_num: Number(sessionStorage.getItem('Office_num')),
            Delicately: sessionStorage.getItem('Delicately') === "false" ? false : true,
            Infill: sessionStorage.getItem('Infill') === "false" ? false : true,
            Type: sessionStorage.getItem('Type'),
            Material : sessionStorage.getItem('Material'),
            Weight : Number(sessionStorage.getItem('Weight')),
            Volume : Number(sessionStorage.getItem('Volume')),
            Size_x : Number(sessionStorage.getItem('Size_x')),
            Size_y : Number(sessionStorage.getItem('Size_y')),
            Size_z : Number(sessionStorage.getItem('Size_z')),
            Hour: Number(sessionStorage.getItem('Hour')),
            Minute: Number(sessionStorage.getItem('Minute')),
            Second: Number(sessionStorage.getItem('Second')),
            Price: sessionStorage.getItem('Price'),
            Is_shipping: sessionStorage.getItem('Is_shipping') === "false" ? false : true,
            Shipping_address: sessionStorage.getItem('Shipping_address'),
            Shipping_with_mount: sessionStorage.getItem('Shipping_with_mount'),
            Shipping_with_name: sessionStorage.getItem('Shipping_with_name'),
            Pic_url: sessionStorage.getItem('Pic_url')
          }

        const requestPrint=  async () =>{
            const response =  await axios.post(TookDakBaseUrl + `/request-print`,requestPrintData);
            return response
        }
        requestPrint().then( async(response) => {
            console.log(response)
            window.location = "/"
        }).catch(async (error) => {
            console.error(error.response);
            alert("결제는 성공했으나 출력 요청에 실패했습니다. 문의사항에 남겨주시면 최대한 빠르게 답변드리겠습니다.")
            window.location = "/"
        });

    }

    const RegisterUserAPI = async (name, phone_num, email) => {
        const userID = name
        const password = phone_num
        const registerUserData = {
            id : userID,
            password : password,
            name : name,
            phone_num : phone_num,
            email : email
        }

        const postRegisterUser = async () =>{
            try {
                const response = await axios.post(TookDakBaseUrl + `/register-user`,registerUserData);
                return response;
            } catch (error) {
                console.error('Error in postRegisterUser:', error.response);
                throw error; // 에러를 다시 던져서 상위 함수에서 처리할 수 있게 합니다.
            }
        }
        try {
            const response = await postRegisterUser();
            console.log(response);
            return response.data.message;
        } catch (error) {
            console.error('Error in RegisterUserAPI:', error);
        }
    }
    useEffect(() => {
        // const jquery = document.createElement("script");
        // jquery.src = "https://code.jquery.com/jquery-1.12.4.min.js";
        const iamport = document.createElement("script");
        iamport.src = "https://cdn.iamport.kr/v1/iamport.js";
        //document.head.appendChild(jquery);
        document.head.appendChild(iamport);
        return () => {
        	//document.head.removeChild(jquery);
        	document.head.removeChild(iamport);
        }
    }, []);
    const PaymentPopup = (props) => {
        const { IMP } = window;
    	IMP.init("imp11527355");
      console.log(props)
      IMP.request_pay({
    		pg: 'html5_inicis',
    		pay_method: 'card',
    		merchant_uid: `mid_${new Date().getTime()}`,
    		name: props.filename,
    		amount: props.TotalPrice,
    		custom_data: {
                name: '부가정보',
                desc: '세부 부가정보'
    		},
    		buyer_name: props.userName,
    		buyer_tel: props.userPhoneNum,
    		buyer_email: props.userEmail,
    		buyer_addr: props.addr,
    		buyer_postalcode: props.postcode
    	}, function(rsp) {
            if ( rsp.success ) {
                alert("결제성공");
                RequestPrintAPI()
                
            } else {
                alert("결제실패");
                RequestPrintAPI()
                //나중에 결제 실패에 ReauestAPI는 지워야함
                //window.location = "/"
            }
        });

    }
    
    const [checkboxes, setCheckboxes] = useState([
        { id: 1, label: '바코드 수신 후 7일 이내 매장에서 수령하지 않으실 경우 출력물은 폐기됩니다.', isChecked: false },
        { id: 2, label: '서포트는 제거되지 않은 상태로 제공됩니다.', isChecked: false },
        { id: 3, label: '본인은 주문 처리, 결제 확인, 배송 업데이트 및 기타 관련 안내를 위해 이메일을 수신하는 데 동의합니다.', isChecked: false },
        { id: 4, label: '주문할 상품, 가격, 배송정보, 취소 환불정책을 최종 확인하였으며, 구매에 동의합니다. (전자상거래법 제 8조 2항)', isChecked: false },
        { id: 5, label: '전자금융거래 기본약관', isChecked: false },
        { id: 6, label: '서비스 약관 동의', isChecked: false },
        // ... 여러 개의 체크박스 정보 추가
      ]);

    const [selectAll, setSelectAll] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false)

    const handleAllConsentWrapperClick = (event) => {
        setIsExpanded(!isExpanded)
        console.log("expanded")
    }

    const handleCheckboxChange = (id) => {
        const updatedCheckboxes = checkboxes.map((checkbox) =>
          checkbox.id === id ? { ...checkbox, isChecked: !checkbox.isChecked } : checkbox
        );
    
        setCheckboxes(updatedCheckboxes);
        // 개별 체크박스의 상태가 변경될 때 전체 선택 상태 업데이트
        updateSelectAllStatus(updatedCheckboxes);
      };

        // 전체 선택/해제 버튼 클릭 시 호출되는 함수
    const handleSelectAllChange = (event) => {
        event.stopPropagation();
        const updatedCheckboxes = checkboxes.map((checkbox) => ({ ...checkbox, isChecked: !selectAll }));
        setCheckboxes(updatedCheckboxes);
        setSelectAll(!selectAll);

        console.log("allcheck")
    };

    // 개별 체크박스의 상태에 따라 전체 선택 상태 업데이트하는 함수
    const updateSelectAllStatus = (updatedCheckboxes) => {
        const allChecked = updatedCheckboxes.every((checkbox) => checkbox.isChecked);
        setSelectAll(allChecked);
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggleModal = () => {
      setIsOpen(!isOpen);
    };
    const CloseModal = () => { 
        setIsOpen(false)
    }

    const isAllValid = 
    receivemethod !== "" ?
        receivemethod === "Delivery" ? 
            isNameVaild && isPhoneNumValid && isEmailValid && isDetailAddressValid  && selectAll
            : 
            isNameVaild && isPhoneNumValid && isEmailValid && selectAll
        : false

    const onPaymentClick = () => {
        //결제 성공 시 api들 호출
        //실패하면 그냥 종료
        if(isAllValid){
            const data = {
                filename : filename,
                TotalPrice : Number(totalPrice),
                userName : userName,
                userPhoneNum : userPhoneNum,
                userEmail : userEmail,
                addr : basicAddress + ' ' + detailAddress,
                postcode : postcode
            }
            const getUserNum=  async () =>{
                const response =  await axios.get(TookDakBaseUrl + `/get-user-num?email=${userEmail}&phone_num=${userPhoneNum}`);
                return response
            }
            getUserNum().then( async(response) => {
                sessionStorage.setItem("User_num",Number(response.data.user_num))
                PaymentPopup(data);
            }).catch(async (error) => {
                console.error(error.response);
                if(error.response.data === "No matching user found"){
                    const curuserNum = await RegisterUserAPI(userName, userPhoneNum, userEmail)
                    sessionStorage.setItem("User_num",Number(curuserNum))
                    PaymentPopup(data);
                }
                else{
                    //있다면 requset-print 호출?
                    alert("결제실패");
                }
            });
        }else{
            return 0;
        }

    }

  return (
    <Container>
        <PaymentInfoContainer>
            <PaymentTitleWrapper>
                <PaymentInfoTitle>결제</PaymentInfoTitle>
                <Filename>{filename}</Filename>
            </PaymentTitleWrapper>
            <DividingLine1/>
            <PaymentInfoInputWrapper>
                <NameInputwrapper>
                    <NameInputTitle>
                        이름 :
                    </NameInputTitle>
                    <NameInput
                        id="name"
                        type="text"
                        onChange = {onChangeName}
                        autoComplete='off'
                    />
                </NameInputwrapper>
                <PhoneInputwrapper>
                    <PhoneInputTitle>
                        핸드폰 번호 :
                    </PhoneInputTitle>
                    <PhoneInput
                        id="phoneNumber"
                        type="text"
                        onChange = {onChangePhoneNum}
                        autoComplete='off'
                    />
                </PhoneInputwrapper>
                <EmailInputwrapper>
                    <EmailInputTitle>
                        이메일 :
                    </EmailInputTitle>
                    <EmailInput
                        id="Email"
                        type="text"
                        onChange = {onChangeEmail}
                        autoComplete='off'
                    />
                </EmailInputwrapper>
                <ReceivemethodInputwrapper>
                    <ReceivemethodTitle>
                        수령 방법 :
                    </ReceivemethodTitle>
                    <ReceivemethodBtnWrapper>
                        <DeliveryBtn 
                            onClick={() => {setReceivemethod("Delivery")
                                            setTotalPrice(Number(normalPrice)+3500)
                                            }}
                            className={receivemethod === 'Delivery' ? 'active' : ''}>택배</DeliveryBtn>
                        <VisitedBtn 
                            onClick={() => {setReceivemethod("Visited")
                                            setTotalPrice(Number(normalPrice)+0)
                                           }}
                            className={receivemethod === 'Visited' ? 'active' : ''}>지점 방문</VisitedBtn>
                    </ReceivemethodBtnWrapper>
                </ReceivemethodInputwrapper>
                {/* 택배 클릭하면 주소 입력칸 나오고 지점방문 클릭하면 Map나오게 */}
                {receivemethod === "" ? 
                    <></> 
                    :
                    receivemethod === "Visited" ?
                        <SelectOfficeWrapper>
                        <SelectOfficeTitle>지점 선택 :</SelectOfficeTitle>
                        <SelectOfficeListandMap>
                            <SelectOfficeList>
                                {officesInfo &&
                                    officesInfo.map((current, index) => (
                                        <OfficeComponent
                                            key={index}
                                            isSelected={selectedOffice === index}
                                            selectOffice={() => setSelectedOffice(index)}
                                            available = {current.available}
                                            isShippingPossible={current.is_shipping_possible}
                                            address={current.office_address}
                                            name={current.name}
                                            num={current.office_num}
                                            predictHour={current.predict_hour}
                                            predictMinute={current.predict_minute}
                                            predictSecond={current.predict_second}
                                            waitJobAmount={current.wait_job_amount}
                                        />
                                    ))}
                            </SelectOfficeList>
                            <ReceivemethodMap>
                                <NavermapsProvider 
                                    ncpClientId={TookDakMapID}>
                                        <MyMap
                                            isSelected={selectedOffice}
                                            officesInfo={officesInfo}
                                        />
                                </NavermapsProvider>
                            </ReceivemethodMap>
                            <PaymentInfoMessageBox_Map>
                                <PaymentInfoMessage>* 주문하신 출력물이 준비되면 자세한 픽업 안내를 이메일로 보내 드립니다.</PaymentInfoMessage>
                                <PaymentInfoMessage>* 자세한 출력 진행상황은 ‘마이페이지’에서 확인할 수 있습니다.</PaymentInfoMessage>
                            </PaymentInfoMessageBox_Map>
                        </SelectOfficeListandMap>
                        </SelectOfficeWrapper>
                    :
                        <AddressInputwrapper>
                            <PaymentInfoMessageBox_Post>
                                {officesInfo &&
                                    <>{
                                        officesInfo[0].predict_hour > 23 ?
                                        <ShippingWaitingTime>대기시간 : {Math.floor(officesInfo[0].predict_hour/24)}일 {officesInfo[0].predict_hour%24}시간 {officesInfo[0].predict_minute}분</ShippingWaitingTime>
                                        :
                                        <ShippingWaitingTime>대기시간 : {officesInfo[0].predict_hour}시간 {officesInfo[0].predict_minute}분</ShippingWaitingTime>

                                    }
                                    </>
                                }
                                <PaymentInfoMessage>*자세한 출력 진행상황은 '마이페이지'에서 확인할 수 있습니다.</PaymentInfoMessage>
                            </PaymentInfoMessageBox_Post>
                            <AddressInputTitle>주소 :</AddressInputTitle>
                            <PostcodeInputWrapper>
                                <PostcodeInput id="address" type="text" placeholder='우편 번호' onClick={openPostCode} value={postcode}/>
                                <PostcodeSearchBtn onClick={openPostCode}>검색</PostcodeSearchBtn>
                                {isModalOpen && (
                                    <PostCodeModalDom
                                        CloseModal={() => {
                                        closePostCode();
                                    }}>
                                        <ModalPostCode 
                                            onClose={closePostCode} 
                                            setPostCode = {setPostCode}
                                            setBasicAddress={setBasicAddress}
                                        />
                                    </PostCodeModalDom>
                                )}
                            </PostcodeInputWrapper>
                            <BasicAddressInput id="address" type="text" placeholder='기본 주소 (도로명 주소 또는 지번 주소)' value={basicAddress}/>
                            <DetailAddressInput id="address" type="text" placeholder='상세 주소' onChange={onChangeDetailAddress} autoComplete='off'/>
                        </AddressInputwrapper>
                }
                <DividingLine2/>
                <PaymentTypeAndPriceWrapper>
                    <PriceWrapper>
                        <PrintPriceWrapper>
                            <PrintPriceTitle>출력</PrintPriceTitle>
                            <PrintPrice>{Number(sessionStorage.getItem("NormalPrice")).toLocaleString()} 원</PrintPrice>
                        </PrintPriceWrapper>
                        <ShippingfeeWrapper>
                            <ShippingfeeTitle>배송</ShippingfeeTitle>
                            <Shippingfee>{receivemethod === 'Delivery' ? '3,500' : '0'} 원</Shippingfee>
                        </ShippingfeeWrapper>
                        <DetailDevidingLine/>
                        <TotalPriceWrapper>
                            <TotalPriceTitle>총계</TotalPriceTitle>
                            <TotalPrice>{Number(totalPrice).toLocaleString()} 원</TotalPrice>
                        </TotalPriceWrapper>
                    </PriceWrapper>
                </PaymentTypeAndPriceWrapper>
                <DividingLine3/>
                <PaymentConsentCheckWrapper>
                    <PaymentAllConsentWrpper onClick={handleAllConsentWrapperClick}>
                        <PaymentConsentCheckLable
                            className='allConsent'>
                            <PaymentConsentCheck
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAllChange}
                            onClick={handleSelectAllChange}
                            />
                            <PaymentConsentCheckDesc>
                                전체 동의
                            </PaymentConsentCheckDesc>
                        </PaymentConsentCheckLable>
                        <IsExpanded 
                            
                            className={isExpanded ? 'expanded' : ''}/>
                    </PaymentAllConsentWrpper>
                    <DetailConsentCheckWrapper>
                        {isExpanded && 
                            <>
                            {checkboxes.map((checkbox) => (
                                <DetailConsentCheckBox key={checkbox.id}>
                                <PaymentConsentCheckLable>
                                    <PaymentConsentCheck
                                    type="checkbox"
                                    checked={checkbox.isChecked}
                                    onChange={() => handleCheckboxChange(checkbox.id)}
                                    />
                                    <PaymentConsentCheckDesc>
                                        {checkbox.label}
                                    </PaymentConsentCheckDesc>
                                    {/*checkbox.id === 5 ||*/ checkbox.id === 6 ? (
                                        <TermsConditonBtn 
                                            onClick={toggleModal}>
                                            약관보기
                                        </TermsConditonBtn>
                                    ) : null}
                                </PaymentConsentCheckLable>
                                </DetailConsentCheckBox>
                            ))}
                            </>
                        }
                    </DetailConsentCheckWrapper>
                </PaymentConsentCheckWrapper>
                <PaymentDescWrapper>
                    <QuestionMark
                        src = {QuestionMark_Support}
                        onMouseEnter={() => setShowPaymentPopup(true)}
                        onMouseOut={() => setShowPaymentPopup(false)} />
                        {showPaymentPopup && <img src={PaymentPopUpUrl} alt="채움정도 팝업" style={{position: "absolute", right:"1040px", zIndex: 10, width : '260px', height : '280px'}}/>}
                    <PaymentDesc><span>간편 결제(네이버 페이, 카카오 페이, 삼성 페이, 애플 페이) 가능</span></PaymentDesc>
                </PaymentDescWrapper>
                <FinalPaymentBtn
                    className={isAllValid ? "allValid" : ""}
                    onClick={onPaymentClick}>PAYMENT</FinalPaymentBtn>
            </PaymentInfoInputWrapper>
        </PaymentInfoContainer>
        {isOpen && (
            <TermsConditionsModalDom
                CloseModal={() => {
                CloseModal();
            }}>
                <TermsConditionsModal/>
            </TermsConditionsModalDom>
        )}
        {LoadingGIFOpen && (
            <LoadingGIF size = {300}/>
        )}
    </Container>
  )
}

export default PaymentInfo